import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FluxDnaSettings } from '../Footer/FluxDnaSettings';
import '../Header/Header.scss';
import MainSiteInnerNav from '../Header/MainSiteInnerNav';

const PrivateResidences = ({ formId, location }) => {
  const menuElement = useRef();
  const menuElementBooking = useRef();
  const [menuOpenStatus, setMenuOpenStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBooking, setMenuOpenBooking] = useState(false);
  const fluxFooterSetting = FluxDnaSettings();
  const [isImage, setisImage] = useState(null);
  const [stepForm, setStepForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [nxtDate, setNxtDate] = useState('');
  const [checked, setChecked] = useState(false);
  const [numNight, setNumNight] = useState();
  const [startD, setStartD] = useState(null);
  const [endD, setEndD] = useState(null);
  const [prevPath, setPrevPath] = useState('/');

  useEffect(() => {
    if (location.state?.prevPath !== undefined) {
      setPrevPath(location.state.prevPath);
    }
  });

  const [form, setForm] = useState({
    input_1: location?.state
      ? location.state.room === undefined ||
        location.state.room === '' ||
        location.state.room === null
        ? ''
        : location.state.room
      : '',
    input_10: '',
    input_13: '',
    input_37: '',
    input_31: '',
    input_34: '',
    input_6: '',
    input_11: '',
    input_39_1: '',
    input_16: 1,
    input_17: 0,
    input_39: 0,
    input_21: '',
    input_20: '',
    input_23: '',
    input_22: '',
    input_26: '',
    input_25: '',
    input_38: '',
    input_40_1: '',
  });

  const [formError, setFormError] = useState({
    input_13: '',
    input_37: '',
    input_31: '',
    input_34: '',
    input_10: '',
    input_6: '',
    input_11: '',
    input_16: '',
    input_21: '',
    input_20: '',
    input_23: '',
    input_22: '',
    input_25: '',
  });

  const goToNextStep = () => {
    document.body.classList.add('loader');
    setIsLoading(true);
    setStepForm((stepForm) => stepForm + 1);
    document.body.classList.remove('loader');
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(form);
  }, [form, setForm]);

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            travelEnquiryBgimagePrivateResidences {
              altText
              mediaItemUrl
              imgixImage {
                fluid(
                  maxWidth: 800
                  maxHeight: 1024
                  imgixParams: { w: 800, h: 1024, fit: "crop" }
                ) {
                  srcWebp
                }
              }
              uri
              title
            }
            travelEnquiryLogo {
              altText
              mediaItemUrl
              uri
              title
            }
          }
          pageTitle
          pageSlug
          fluxDna {
            royalPortfolio {
              cta {
                target
                title
                url
              }
              primaryLogo {
                altText
                mediaItemUrl
                uri
                title
              }
              secondaryLogo {
                altText
                mediaItemUrl
                uri
                title
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    document.body.classList.remove('nav-menu-open');
    document.body.classList.remove('booking-menu-open');
    document.body.classList.remove('loading');
  }, []);

  useEffect(() => {
    const datepickers = document.querySelectorAll(
      '.react-datepicker__input-container input'
    );

    datepickers.forEach((datepicker) => {
      datepicker.setAttribute('autocomplete', 'off');
    });
  }, []);

  useEffect(() => {
    if (startD && endD) {
      const dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 3600 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY + 1);
      };
      const a = startD;
      const b = endD;
      const difference = dateDiffInDays(a, b);
      setNumNight(difference);
    }
  }, [startD, endD, setNumNight]);

  const headerDataList = query.wp.fluxDnaSettings.fluxDna;
  const callback = (image) => {
    if (image) {
      setisImage(image);
    }
  };

  const incrementCounter = (input) => {
    if (input === 16) {
      setForm({ ...form, input_16: form.input_16 + 1 });
    } else if (input === 17) {
      setForm({ ...form, input_17: form.input_17 + 1 });
    } else if (input === 39) {
      setForm({ ...form, input_39: form.input_39 + 1 });
    }
  };

  const decrementCounter = (input) => {
    if (input === 16) {
      if (form.input_16 > 1) {
        setForm({ ...form, input_16: form.input_16 - 1 });
      }
    } else if (input === 17) {
      if (form.input_17 > 0) {
        setForm({ ...form, input_17: form.input_17 - 1 });
      }
    } else if (input === 39) {
      if (form.input_39 > 0) {
        setForm({ ...form, input_39: form.input_39 - 1 });
      }
    }
  };

  return (
    <>
      <>
        {isLoading && (
          <div
            id="loading"
            className="loading-spinner
        "
          ></div>
        )}
        <section className="traveller-enquiry-form bg-white">
          <div className="flex flex-wrap">
            <div className="lg:w-7/12 w-full closemenu">
              <div
                className="xl:px-80 lg:px-40
             px-20 lg:py-40 pb-20 pt-20 relative h-screen overflow-y-auto overflow-x-hidden enquiry-left"
              >
                <div className="enquiry-header mb-50">
                  <div className="flex flex-wrap items-center justify-between">
                    <div className="logo relative z-999">
                      <Link to="/">
                        <img
                          src={
                            headerDataList.royalPortfolio.primaryLogo
                              .mediaItemUrl
                          }
                          alt="Logo"
                          className="m-auto lg:w-[149px] w-[120px] desktop-logo"
                        />
                      </Link>
                      <Link to="/">
                        <img
                          src={
                            headerDataList.royalPortfolio.secondaryLogo
                              .mediaItemUrl
                          }
                          alt="Logo"
                          className="m-auto lg:w-[149px sticky-logo"
                        />
                      </Link>
                    </div>
                    <div className="header-top-right flex ">
                      <div className="pr-20 ">
                        {prevPath === '/guest-enquiry/' ? (
                          <button
                            className="text-12 flex items-center cursor-pointer text-dark"
                            onClick={() => navigate(-2)}
                          >
                            <img
                              className="pr-10 max-w-[15px]"
                              src="/images/back-to-site.png"
                              alt="back to site"
                            />
                            Back to site
                          </button>
                        ) : (
                          <Link
                            className="text-12 flex items-center cursor-pointer text-dark"
                            to={`${prevPath}`}
                          >
                            <img
                              className="pr-10 max-w-[15px]"
                              src="/images/back-to-site.png"
                              alt="back to site"
                            />
                            Back to site
                          </Link>
                        )}
                      </div>
                      <div
                        onClick={() => {
                          document.body.classList.toggle('nav-menu-open');
                          setMenuOpen(!menuOpen);
                        }}
                        className={`navbar-icon flex items-center cursor-pointer hamburger menu-open-test ${
                          menuOpen ? 'active' : ''
                        }`}
                      >
                        <div className="space-y-2 menu-line">
                          <span className="line w-full mx-auto"></span>
                          <span className="line line-middle w-full"></span>
                          <span className="line w-full mx-auto"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="scroll-form-main">
                  <div className="top-info flex flex-wrap items-center justify-between">
                    <h1 className="text-black-500 lg:text-34">
                      Private Residences Enquiry
                    </h1>
                  </div>
                  <div
                    className={`gf-step--rm gf-step ${'step_' + stepForm}`}
                  ></div>
                  <form className="lg:mt-30 mt-20">
                    <div className="form-wrapper">
                      {stepForm === 1 && (
                        <div className="form-row">
                          <h6 className="text-dark tracking-006em capitalize lg:text-24">
                            Select your private residence location
                          </h6>
                          <div className="flex flex-wrap lg:mx-minus-20">
                            <div className="lg:w-12/12 w-full lg:px-20">
                              <div className="form-group grid-cols-2 mdscreen:grid-cols-1 grid gap-y-6 relative overflow-hidden lg:mt-30 mt-10">
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Hermanus"
                                    checked={form.input_6 === 'Hermanus'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_6: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_6: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_6: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_6: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Hermanus"
                                  />
                                  <label
                                    htmlFor="Hermanus"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Hermanus</h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Greater Kruger"
                                    checked={form.input_6 === 'Greater Kruger'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_6: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_6: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_6: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_6: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Greater Kruger"
                                  />
                                  <label
                                    htmlFor="Greater Kruger"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Greater Kruger </h4>
                                  </label>
                                </div>
                              </div>
                              {formError.input_6 && (
                                <div className="error mt-20">
                                  <span className="text-red">
                                    {formError.input_6}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {stepForm === 2 && form.input_6 === 'Hermanus' && (
                        <div className="form-row">
                          <h6 className="text-dark tracking-006em capitalize lg:text-24">
                            Private Residences in Hermanus
                          </h6>
                          <div className="flex flex-wrap lg:mx-minus-20">
                            <div className="lg:w-12/12 w-full lg:px-20">
                              <div className="form-group grid-cols-2 mdscreen:grid-cols-1 grid gap-y-6 relative overflow-hidden lg:mt-30 mt-10">
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Kliphuis"
                                    checked={form.input_34 === 'Kliphuis'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Kliphuis"
                                  />
                                  <label
                                    htmlFor="Kliphuis"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Kliphuis </h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="White House"
                                    checked={form.input_34 === 'White House'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="White House"
                                  />
                                  <label
                                    htmlFor="White House"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> White House </h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Beach House"
                                    checked={form.input_34 === 'Beach House'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Beach House"
                                  />
                                  <label
                                    htmlFor="Beach House"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Beach House </h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Poseidon House"
                                    checked={form.input_34 === 'Poseidon House'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Poseidon House"
                                  />
                                  <label
                                    htmlFor="Poseidon House"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Poseidon House </h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Fernkloof"
                                    checked={form.input_34 === 'Fernkloof'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_34: e.target.value,
                                        input_37: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_34: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Fernkloof"
                                  />
                                  <label
                                    htmlFor="Fernkloof"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Fernkloof </h4>
                                  </label>
                                </div>
                              </div>
                              {formError.input_34 && (
                                <div className="error mt-20">
                                  <span className="text-red">
                                    {formError.input_6}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {stepForm === 2 && form.input_6 === 'Greater Kruger' && (
                        <div className="form-row">
                          <h6 className="text-dark tracking-006em capitalize lg:text-24">
                            Private Residences in Greater Kruger
                          </h6>
                          <div className="flex flex-wrap lg:mx-minus-20">
                            <div className="lg:w-12/12 w-full lg:px-20">
                              <div className="form-group grid-cols-2 mdscreen:grid-cols-1 grid gap-y-6 relative overflow-hidden lg:mt-30 mt-10">
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="The Farmhouse"
                                    checked={form.input_37 === 'The Farmhouse'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_37: e.target.value,
                                        input_34: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_37: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_37: e.target.value,
                                        input_34: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_37: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="The Farmhouse"
                                  />
                                  <label
                                    htmlFor="The Farmhouse"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4>The Farmhouse</h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Africa House"
                                    checked={form.input_37 === 'Africa House'}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_37: e.target.value,
                                        input_34: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_37: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_37: e.target.value,
                                        input_34: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_37: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Africa House"
                                  />
                                  <label
                                    htmlFor="Africa House"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4>Africa House</h4>
                                  </label>
                                </div>
                                <div className="radio-bx w-[285px]">
                                  <input
                                    type="radio"
                                    value="Waterside House"
                                    checked={
                                      form.input_37 === 'Waterside House'
                                    }
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_37: e.target.value,
                                        input_34: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_37: '',
                                      });
                                    }}
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        input_37: e.target.value,
                                        input_34: '',
                                      });
                                      setFormError({
                                        ...formError,
                                        input_37: '',
                                      });
                                      goToNextStep();
                                    }}
                                    id="Waterside House"
                                  />
                                  <label
                                    htmlFor="Waterside House"
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> Waterside House</h4>
                                  </label>
                                </div>
                              </div>
                              {formError.input_6 && (
                                <div className="error mt-20">
                                  <span className="text-red">
                                    {formError.input_6}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {stepForm === 3 && (
                        <div className="form-row">
                          <h6 className="text-dark lg:text-24">
                            When will you be travelling?
                          </h6>
                          <div className="flex flex-wrap lg:mx-minus-20">
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-black-400 text-16"
                                  htmlFor=""
                                >
                                  Arrival date
                                </label>
                                <DatePicker
                                  className="enquiry-date-picker"
                                  selected={form.input_10}
                                  placeholderText="Select Date"
                                  dateFormat="dd/MM/yyyy"
                                  minDate={new Date()}
                                  onChange={(date) => {
                                    setForm({
                                      ...form,
                                      input_10: date,
                                    });

                                    var date = new Date(date);
                                    date.setDate(date.getDate() + 1);
                                    setNxtDate(date);
                                    setStartD(date);
                                    setFormError({
                                      ...formError,
                                      input_10: '',
                                    });
                                  }}
                                />
                              </div>

                              {formError.input_10 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_10}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-black-400 text-16"
                                  htmlFor=""
                                >
                                  Departure date
                                </label>
                                <DatePicker
                                  selected={form.input_11}
                                  disabled={form.input_10 === '' ? true : false}
                                  minDate={nxtDate}
                                  placeholderText="Select Date"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setForm({
                                      ...form,
                                      input_11: date,
                                    });
                                    setEndD(date);
                                    setFormError({
                                      ...formError,
                                      input_11: '',
                                    });
                                  }}
                                />
                              </div>
                              {formError.input_11 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_11}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="px-20 pt-15">
                              {numNight && (
                                <p>
                                  You've selected a{' '}
                                  <strong>{numNight} night</strong> stay.
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <div className="radio-bx checkbox-bx">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  checked={checked}
                                  onChange={(e) => {
                                    setChecked(e.target.checked);
                                    setForm({
                                      ...form,
                                      input_39_1: e.target.value,
                                    });
                                  }}
                                  id="input_39_1"
                                />
                                <label
                                  htmlFor="input_39_1"
                                  className="text-dark text-16"
                                >
                                  I am unsure of my exact dates
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {stepForm === 4 && (
                        <div className="form-row">
                          <h6 className="text-dark lg:text-24">
                            Who will you be travelling with?
                          </h6>
                          <div className="flex flex-wrap lg:mx-minus-20">
                            <div className="w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full mr-[20px] text-black-400 text-16"
                                  htmlFor=""
                                >
                                  Adults*
                                </label>
                                <div class="counter flex">
                                  <span
                                    class="counter__minus flex items-center justify-center mt-10 w-[40px] border-1 border-gray-700 border-opacity-400 bg-gray-200 bg-opacity-100 py-2 px-4 text-sm outline-2 outline-transparent-gray-200 hover:cursor-pointer"
                                    onClick={() => decrementCounter(16)}
                                  >
                                    <span className="minus-wrapper text-[20px]">
                                      -
                                    </span>
                                  </span>
                                  <input
                                    class="counter__input !w-[20px]"
                                    type="number"
                                    value={form.input_16}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_16: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_16: '',
                                      });
                                    }}
                                  />
                                  <span
                                    class="counter__plus flex items-center justify-center mt-10 w-[40px] border-1 border-gray-700 border-opacity-400 bg-gray-200 bg-opacity-100 py-2 px-4 text-sm outline-2 outline-transparent-gray-200 hover:cursor-pointer"
                                    onClick={() => incrementCounter(16)}
                                  >
                                    <span className="plus-wrapper text-[16px]">
                                      +
                                    </span>
                                  </span>
                                </div>
                              </div>

                              {formError.input_16 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_16}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full mr-[20px] text-black-400 text-16"
                                  htmlFor=""
                                >
                                  Children over 10
                                </label>
                                <div class="counter flex">
                                  <span
                                    class="counter__minus flex items-center justify-center mt-10 w-[40px] border-1 border-gray-700 border-opacity-400 bg-gray-200 bg-opacity-100 py-2 px-4 text-sm outline-2 outline-transparent-gray-200 hover:cursor-pointer"
                                    onClick={() => decrementCounter(17)}
                                  >
                                    <span className="minus-wrapper text-[20px]">
                                      -
                                    </span>
                                  </span>
                                  <input
                                    class="counter__input !w-[20px]"
                                    type="number"
                                    value={form.input_17}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_17: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_17: '',
                                      });
                                    }}
                                  />
                                  <span
                                    class="counter__plus flex items-center justify-center mt-10 w-[40px] border-1 border-gray-700 border-opacity-400 bg-gray-200 bg-opacity-100 py-2 px-4 text-sm outline-2 outline-transparent-gray-200 hover:cursor-pointer"
                                    onClick={() => incrementCounter(17)}
                                  >
                                    <span className="plus-wrapper text-[16px]">
                                      +
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full mr-[20px] text-black-400 text-16"
                                  htmlFor=""
                                >
                                  Children under 10
                                </label>
                                <div class="counter flex">
                                  <span
                                    class="counter__minus flex items-center justify-center mt-10 w-[40px] border-1 border-gray-700 border-opacity-400 bg-gray-200 bg-opacity-100 py-2 px-4 text-sm outline-2 outline-transparent-gray-200 hover:cursor-pointer"
                                    onClick={() => decrementCounter(39)}
                                  >
                                    <span className="minus-wrapper text-[20px]">
                                      -
                                    </span>
                                  </span>
                                  <input
                                    class="counter__input !w-[20px]"
                                    type="number"
                                    value={form.input_39}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_39: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_39: '',
                                      });
                                    }}
                                  />
                                  <span
                                    class="counter__plus flex items-center justify-center mt-10 w-[40px] border-1 border-gray-700 border-opacity-400 bg-gray-200 bg-opacity-100 py-2 px-4 text-sm outline-2 outline-transparent-gray-200 hover:cursor-pointer"
                                    onClick={() => incrementCounter(39)}
                                  >
                                    <span className="plus-wrapper text-[16px]">
                                      +
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {stepForm === 5 && (
                        <div className="form-row">
                          <h6 className="text-dark lg:text-24">
                            Guest information
                          </h6>
                          <div className="flex flex-wrap lg:mx-minus-20">
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-brown text-6"
                                  htmlFor=""
                                >
                                  First Name*
                                </label>
                                <input
                                  type="text"
                                  value={form.input_20}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_20: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_20: '',
                                    });
                                  }}
                                  placeholder="Type here..."
                                />
                              </div>

                              {formError.input_20 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_20}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-brown text-6"
                                  htmlFor=""
                                >
                                  Last Name*
                                </label>
                                <input
                                  type="text"
                                  value={form.input_21}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_21: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_21: '',
                                    });
                                  }}
                                  placeholder="Type here..."
                                />
                              </div>
                              {formError.input_21 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_21}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-brown text-6"
                                  htmlFor=""
                                >
                                  Phone Number*
                                </label>
                                <input
                                  type="text"
                                  value={form.input_23}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_23: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_23: '',
                                    });
                                  }}
                                  placeholder="Type here..."
                                />
                              </div>
                              {formError.input_23 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_23}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-brown text-6"
                                  htmlFor=""
                                >
                                  Email Address*
                                </label>
                                <input
                                  type="text"
                                  value={form.input_22}
                                  onBlur={(e) => {
                                    var value = e.target.value;
                                    var pattern =
                                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                    if (!pattern.test(value)) {
                                      setForm({ ...form, input_22: '' });
                                      setFormError({
                                        ...formError,
                                        input_22: 'Please enter valid email.',
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_22: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_22: '',
                                    });
                                  }}
                                  placeholder="Type here..."
                                />
                              </div>
                              {formError.input_22 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_22}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-brown text-6"
                                  htmlFor=""
                                >
                                  Country of Residence*
                                </label>
                                <select
                                  value={form.input_25}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_25: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_25: '',
                                    });
                                  }}
                                >
                                  <option value="">Select Country</option>
                                  <option value="Afghanistan">
                                    Afghanistan
                                  </option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">
                                    American Samoa
                                  </option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antarctica">Antarctica</option>
                                  <option value="Antigua and Barbuda">
                                    Antigua and Barbuda
                                  </option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire, Sint Eustatius and Saba">
                                    Bonaire, Sint Eustatius and Saba
                                  </option>
                                  <option value="Bosnia and Herzegovina">
                                    Bosnia and Herzegovina
                                  </option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Bouvet Island">
                                    Bouvet Island
                                  </option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Territory">
                                    British Indian Ocean Territory
                                  </option>
                                  <option value="Brunei Darussalam">
                                    Brunei Darussalam
                                  </option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">
                                    Burkina Faso
                                  </option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">
                                    Cayman Islands
                                  </option>
                                  <option value="Central African Republic">
                                    Central African Republic
                                  </option>
                                  <option value="Chad">Chad</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">
                                    Christmas Island
                                  </option>
                                  <option value="Cocos Islands">
                                    Cocos Islands
                                  </option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo, Democratic Republic of the">
                                    Congo, Democratic Republic of the
                                  </option>
                                  <option value="Congo, Republic of the">
                                    Congo, Republic of the
                                  </option>
                                  <option value="Cook Islands">
                                    Cook Islands
                                  </option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaçao">Curaçao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">
                                    Czech Republic
                                  </option>
                                  <option value="Côte d'Ivoire">
                                    Côte d'Ivoire
                                  </option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">
                                    Dominican Republic
                                  </option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">
                                    El Salvador
                                  </option>
                                  <option value="Equatorial Guinea">
                                    Equatorial Guinea
                                  </option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Eswatini (Swaziland)">
                                    Eswatini (Swaziland)
                                  </option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">
                                    Falkland Islands
                                  </option>
                                  <option value="Faroe Islands">
                                    Faroe Islands
                                  </option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">
                                    French Guiana
                                  </option>
                                  <option value="French Polynesia">
                                    French Polynesia
                                  </option>
                                  <option value="French Southern Territories">
                                    French Southern Territories
                                  </option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guernsey">Guernsey</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guinea-Bissau">
                                    Guinea-Bissau
                                  </option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Heard and McDonald Islands">
                                    Heard and McDonald Islands
                                  </option>
                                  <option value="Holy See">Holy See</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="India">India</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">
                                    Isle of Man
                                  </option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jersey">Jersey</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Lao People's Democratic Republic">
                                    Lao People's Democratic Republic
                                  </option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">
                                    Liechtenstein
                                  </option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">
                                    Marshall Islands
                                  </option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Micronesia">Micronesia</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montenegro">Montenegro</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Namibia">Namibia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                  <option value="New Caledonia">
                                    New Caledonia
                                  </option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">
                                    Norfolk Island
                                  </option>
                                  <option value="North Korea">
                                    North Korea
                                  </option>
                                  <option value="Northern Mariana Islands">
                                    Northern Mariana Islands
                                  </option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau">Palau</option>
                                  <option value="Palestine, State of">
                                    Palestine, State of
                                  </option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">
                                    Papua New Guinea
                                  </option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Philippines">
                                    Philippines
                                  </option>
                                  <option value="Pitcairn">Pitcairn</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">
                                    Puerto Rico
                                  </option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="Réunion">Réunion</option>
                                  <option value="Saint Barthélemy">
                                    Saint Barthélemy
                                  </option>
                                  <option value="Saint Helena">
                                    Saint Helena
                                  </option>
                                  <option value="Saint Kitts and Nevis">
                                    Saint Kitts and Nevis
                                  </option>
                                  <option value="Saint Lucia">
                                    Saint Lucia
                                  </option>
                                  <option value="Saint Martin">
                                    Saint Martin
                                  </option>
                                  <option value="Saint Pierre and Miquelon">
                                    Saint Pierre and Miquelon
                                  </option>
                                  <option value="Saint Vincent and the Grenadines">
                                    Saint Vincent and the Grenadines
                                  </option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome and Principe">
                                    Sao Tome and Principe
                                  </option>
                                  <option value="Saudi Arabia">
                                    Saudi Arabia
                                  </option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Serbia">Serbia</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">
                                    Sierra Leone
                                  </option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Sint Maarten">
                                    Sint Maarten
                                  </option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">
                                    Solomon Islands
                                  </option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">
                                    South Africa
                                  </option>
                                  <option value="South Georgia">
                                    South Georgia
                                  </option>
                                  <option value="South Korea">
                                    South Korea
                                  </option>
                                  <option value="South Sudan">
                                    South Sudan
                                  </option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Svalbard and Jan Mayen Islands">
                                    Svalbard and Jan Mayen Islands
                                  </option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">
                                    Switzerland
                                  </option>
                                  <option value="Syria">Syria</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Timor-Leste">
                                    Timor-Leste
                                  </option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad and Tobago">
                                    Trinidad and Tobago
                                  </option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">
                                    Turkmenistan
                                  </option>
                                  <option value="Turks and Caicos Islands">
                                    Turks and Caicos Islands
                                  </option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="US Minor Outlying Islands">
                                    US Minor Outlying Islands
                                  </option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Emirates">
                                    United Arab Emirates
                                  </option>
                                  <option value="United Kingdom">
                                    United Kingdom
                                  </option>
                                  <option value="United States">
                                    United States
                                  </option>
                                  <option value="Uruguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands, British">
                                    Virgin Islands, British
                                  </option>
                                  <option value="Virgin Islands, U.S.">
                                    Virgin Islands, U.S.
                                  </option>
                                  <option value="Wallis and Futuna">
                                    Wallis and Futuna
                                  </option>
                                  <option value="Western Sahara">
                                    Western Sahara
                                  </option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                  <option value="Åland Islands">
                                    Åland Islands
                                  </option>
                                </select>
                              </div>
                              {formError.input_25 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_25}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="lg:w-6/12 w-full lg:px-20">
                              <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                                <label
                                  className="w-full text-brown text-6"
                                  htmlFor=""
                                >
                                  How should we reach you?*
                                </label>
                                <select
                                  value={form.input_26}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_26: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Select Option...</option>
                                  <option value="Phone">Phone</option>
                                  <option value="Email">Email</option>
                                </select>
                              </div>
                              {formError.input_26 && (
                                <div className="error">
                                  <span className="text-red">
                                    {formError.input_26}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {stepForm === 6 && (
                        <div className="form-group text-area flex flex-wrap lg:mt-30 mt-10">
                          <label
                            className="w-full text-brown text-6 !pl-0"
                            htmlFor=""
                          >
                            Is there anything else you would like us to know?
                          </label>
                          <textarea
                            onChange={(e) => {
                              setForm({
                                ...form,
                                input_38: e.target.value,
                              });
                              setFormError({
                                ...formError,
                                input_38: '',
                              });
                            }}
                            value={form.input_38}
                          ></textarea>
                          <div className="form-group text-area flex flex-wrap lg:mt-30 mt-10">
                            <div className="radio-bx checkbox-bx">
                              <input
                                type="checkbox"
                                value="Yes"
                                checked={form.input_40_1 === 'Yes'}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setForm({
                                    ...form,
                                    input_40_1: checked ? 'Yes' : '',
                                  });
                                }}
                                id="input_40_1"
                              />
                              <label
                                htmlFor="input_40_1"
                                className="text-dark text-16"
                              >
                                I agree to receive news and updates and consent to my submitted data being collected and stored
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={`form-btn flex lg:mt-50 mt-20 items-center ${
                          stepForm !== 1 || prevPath === '/guest-enquiry/'
                            ? 'justify-between'
                            : 'justify-end'
                        }`}
                      >
                        {stepForm === 1 && prevPath === '/guest-enquiry/' && (
                          <a
                            onClick={() => {
                              navigate(-1);
                            }}
                            className="link-btn  cursor-pointer"
                          >
                            Back
                          </a>
                        )}
                        {stepForm > 1 && (
                          <a
                            onClick={() => {
                              document.body.classList.add('loader');
                              setIsLoading(true);
                              setStepForm((stepForm) => stepForm - 1);
                              document.body.classList.remove('loader');
                              setIsLoading(false);
                              setFormError(false);
                            }}
                            className="link-btn  cursor-pointer"
                          >
                            Back
                          </a>
                        )}
                        {stepForm !== 6 ? (
                          <a
                            onClick={() => {
                              if (stepForm === 1) {
                                setFormError({
                                  ...formError,
                                  input_6:
                                    form.input_6 === ''
                                      ? 'Please select private residence location.'
                                      : '',
                                });

                                if (form.input_6 !== '') {
                                  document.body.classList.add('loader');
                                  setIsLoading(true);
                                  setStepForm((stepForm) => stepForm + 1);
                                  document.body.classList.remove('loader');
                                  setIsLoading(false);
                                }
                              } else if (stepForm === 2) {
                                document.body.classList.add('loader');
                                setIsLoading(true);
                                setStepForm((stepForm) => stepForm + 1);
                                document.body.classList.remove('loader');
                                setIsLoading(false);
                              } else if (stepForm === 3) {
                                setFormError({
                                  ...formError,
                                  input_10:
                                    form.input_10 === ''
                                      ? 'Please select arrival date.'
                                      : '',
                                  input_11:
                                    form.input_11 === ''
                                      ? 'Please select departure date.'
                                      : '',
                                });

                                if (
                                  form.input_10 !== '' &&
                                  form.input_11 !== ''
                                ) {
                                  document.body.classList.add('loader');
                                  setIsLoading(true);
                                  setStepForm((stepForm) => stepForm + 1);
                                  document.body.classList.remove('loader');
                                  setIsLoading(false);
                                }
                              } else if (stepForm === 4) {
                                setFormError({
                                  ...formError,
                                  input_16:
                                    form.input_16 === ''
                                      ? 'Please select number of adults.'
                                      : '',
                                });

                                if (form.input_16 !== '') {
                                  document.body.classList.add('loader');
                                  setIsLoading(true);
                                  setStepForm((stepForm) => stepForm + 1);
                                  document.body.classList.remove('loader');
                                  setIsLoading(false);
                                }
                              } else if (stepForm === 5) {
                                var validRegex =
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                setFormError({
                                  ...formError,
                                  input_20:
                                    form.input_20 === ''
                                      ? 'Please enter first name.'
                                      : '',
                                  input_26:
                                    form.input_26 === ''
                                      ? 'Please select a preferred contact method.'
                                      : '',
                                  input_21:
                                    form.input_21 === ''
                                      ? 'Please enter last name.'
                                      : '',
                                  input_23:
                                    form.input_23 === ''
                                      ? 'Please enter phone number.'
                                      : '',
                                  input_22:
                                    form.input_22 === '' ||
                                    !form.input_22.match(validRegex)
                                      ? 'Please enter valid email.'
                                      : '',
                                  input_25:
                                    form.input_25 === ''
                                      ? 'Please enter country.'
                                      : '',
                                });
                                if (
                                  form.input_22 !== '' &&
                                  form.input_23 !== '' &&
                                  form.input_25 !== '' &&
                                  form.input_21 !== '' &&
                                  form.input_20 !== ''
                                ) {
                                  document.body.classList.add('loader');
                                  setIsLoading(true);
                                  setStepForm((stepForm) => stepForm + 1);
                                  document.body.classList.remove('loader');
                                  setIsLoading(false);
                                }
                              }
                            }}
                            className="button button-gray cursor-pointer"
                          >
                            Continue
                          </a>
                        ) : (
                          <a
                            href="#"
                            onClick={() => {
                              if (
                                form.input_22 !== '' &&
                                form.input_23 !== '' &&
                                form.input_25 !== '' &&
                                form.input_21 !== '' &&
                                form.input_20 !== ''
                              ) {
                                document.body.classList.add('loader');
                                setIsLoading(true);

                                if (form.input_10) {
                                  form.input_10 =
                                    form.input_10.getMonth() +
                                    1 +
                                    '/' +
                                    form.input_10.getDate() +
                                    '/' +
                                    form.input_10.getFullYear();
                                }

                                if (form.input_11) {
                                  form.input_11 =
                                    form.input_11.getMonth() +
                                    1 +
                                    '/' +
                                    form.input_11.getDate() +
                                    '/' +
                                    form.input_11.getFullYear();
                                }

                                const requestOptions = {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                  body: JSON.stringify(form),
                                };
                                const url = `${process.env.GATSBY_WP_URL}wp-json/gf/v2/forms/${formId}/submissions`;
                                setFormError({
                                  ...formError,
                                  input_26: '',
                                });
                                fetch(url, requestOptions).then((response) => {
                                  if (response.status === 200) {
                                    document.body.classList.remove('loader');
                                    setIsLoading(false);
                                    navigate('/thank-you');
                                  } else {
                                    setStepForm(1);
                                    setFormError({
                                      ...formError,
                                      input_1: 'Please try again.',
                                    });
                                  }
                                });
                              }
                            }}
                            className="button button-gray cursor-pointer"
                          >
                            Send Enquiry
                          </a>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="lg:w-5/12 w-full">
              <div className="img h-full">
                <img
                  src={
                    headerDataList.travelEnquiryBgimagePrivateResidences
                      .imgixImage.fluid.srcWebp
                  }
                  alt={
                    headerDataList.travelEnquiryBgimagePrivateResidences.altText
                  }
                  width="800"
                  height="1024"
                  className="w-full h-screen object-cover"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>
        <div className="main-nav nav-container">
          <div className="menu-overlay h-full fixed w-full top-0 left-0 bg-gray2"></div>
          <div className="mainNavimg mdscreen:hidden">
            <img
              src={
                headerDataList.travelEnquiryBgimagePrivateResidences.imgixImage
                  .fluid.srcWebp
              }
              alt={headerDataList.travelEnquiryBgimagePrivateResidences.altText}
              width="800"
              height="1024"
              className="w-full h-screen object-cover"
              loading="lazy"
            />
          </div>
          <div className="mainNav__wrap relative m-auto p-menu_mobile z-99 h-full">
            <div className="container-fluid h-full">
              <div className="mainNav__wrap--inner h-full flex flex-col justify-center  mobile-menu">
                <div className="navbarMoile lg:pl-80 pt-20">
                  <div className="max-w-[600px] lgscreen:max-w-[350px] smscreen:max-w-[280px] flex flex-col">
                    <div className="mainNav__col navMenu relative mainNav__col--right flex flex-col pt-20">
                      <div className="btn-custom bk-btn relative z-999 hidden">
                        <div
                          onClick={() => {
                            setMenuOpenBooking(!menuOpenBooking);
                          }}
                          className={`button button-transparent cursor-pointer hamburger menu-open-test transition-all duration-300 ${
                            menuOpenBooking ? 'active' : ''
                          }`}
                        >
                          Make A Booking
                        </div>
                      </div>
                      <MainSiteInnerNav callback={callback} />
                    </div>
                  </div>
                  <div className="w-7/12 mdscreen:w-full">
                    <div className="flex justify-between flex-col items-start">
                      <div className="header-social-icon">
                        <div className="footer-info pt-20">
                          {parse(fluxFooterSetting.footer.contactInformation)}
                          <Link
                            to={headerDataList.royalPortfolio.cta.url}
                            className="link-btn"
                          >
                            {headerDataList.royalPortfolio.cta.title}
                          </Link>
                          <div className="sicon">
                            <ul className="flex flex-wrap items-center mt-20 lg:space-x-5 lgscreen:space-x-3 ">
                              {fluxFooterSetting.footer.socialIcons.map(
                                (item, i) => {
                                  return (
                                    <li>
                                      <Link key={i} to={item.image.uri}>
                                        <img
                                          src={item.image.mediaItemUrl}
                                          alt={item.image.altText}
                                          className=""
                                        />
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PrivateResidences;
export const Head = () => (
  <title>Private Residence Enquiry | The Royal Portfolio</title>
);
